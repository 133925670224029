<template>
    <div class="solution">
        <a href="javascript:void(0);" class="scrollT" ref="solution"></a>
        <div class="sub-banner" :style="{'backgroundImage':'url('+introduce.banner+')'}">
			<div class="container">
				<div class="main">
					<h2 class="wow fadeInDown" v-html="introduce.banner_txt"></h2>
				</div>
			</div>
            <div class="nav wow fadeInUp">
                <div class="container">
                    <router-link
                        v-for="item in navList"
                        :key="item.id" 
                        :to="`/solution?id=${item.id}`"
                        :class="sId === item.id ? 'active':''">
                        {{ item.title }}
                    </router-link>
                </div>
            </div>
		</div>
        <div class="inside-nav wow fadeInDown">
			<div class="container">
				<p class="xs-show">01 Introduction</p>
				<div class="list">
					<a href="javascript:void(0);" @click="goAnchor(0)">01 <br/>Introduction</a>
					<a href="javascript:void(0);" @click="goAnchor(1)">02 <br/>Program value</a>
					<a href="javascript:void(0);" @click="goAnchor(2)">03 <br/>Typical product</a>
					<a href="javascript:void(0);" @click="goAnchor(3)">04 <br/>Success case</a>
				</div>
			</div>
		</div>
        <div class="mainbody">
            <div class="introduce-box cell-box">
                <a href="javascript:void(0);" class="anchor" ref="intr"></a>
                <div class="container">
                    <h3 class="wow fadeInLeft">Introduction</h3>
                    <div class="right wow fadeInRight">
                        <div class="imgBg" :style="{'backgroundImage':'url('+ introduce.pic +')'}"></div>
                        <div class="main">
                            <div class="name">{{ introduce.title }}</div>
                            <div class="info">
                                {{ introduce.detail }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="program-box cell-box">
                <a href="javascript:void(0);" class="anchor" ref="prog"></a>
                <div class="container">
                    <h3 class="wow fadeInLeft">Program value</h3>
                    <div class="right">
                        <div class="info wow fadeInRight">
                            {{ pramTitle }}
                        </div>
                        <div class="list">
                            <div class="item wow fadeInUp"
                                v-for="(item, index) in pramList"
                                :key="index" 
                                :data-wow-delay="(index+1)/5 + 's'">
                                <div class="img">
                                    <img :src="item.pic" alt="">
                                </div>
                                <div class="name">
                                    {{ item.title }}
                                </div>
                                <div class="txt">
                                    {{ item.value }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="typical-box cell-box">
                <a href="javascript:void(0);" class="anchor" ref="typi"></a>
                <div class="container">
                    <h3 class="wow fadeInLeft">Typical product</h3>
                    <div class="right wow fadeInRight">
                        <div class="swiper-container swiper-typical">
                            <div class="swiper-wrapper">
                                <div class="swiper-slide"
                                    v-for="pItem in proList"
                                    :key="pItem.id">
                                    
                                    <a v-if="pItem.fileInfo.files === ''" href="javascript:void(0);">
                                        <div class="name">{{ pItem.title }}</div>
                                        <div class="txt">
                                            <p v-for="(tItem,index) in pItem.parsList" :key="index">
                                                {{ tItem.title+' '+tItem.value }}
                                            </p>
                                        </div>
                                        <div class="img">
                                            <img :src="pItem.pic" alt="">
                                        </div>
                                        <div class="more">Read More<i></i></div>
                                    </a>
                                    <a v-else :href="pItem.fileInfo.files" target="_blank">

                                        <div class="name">{{ pItem.title }}</div>
                                        <div class="txt">
                                            <p v-for="(tItem,index) in pItem.parsList" :key="index">
                                                {{ tItem.title+' '+tItem.value }}
                                            </p>
                                        </div>
                                        <div class="img">
                                            <img :src="pItem.pic" alt="">
                                        </div>
                                        <div class="more">Read More<i></i></div>
                                    </a>
                                </div>
                                
                            </div>
                            <div class="swiper-button-next fcc"><i></i></div>
                            <div class="swiper-button-prev fcc"><i></i></div>
                            <div class="swiper-pagination"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="case-box cell-box">
                <a href="javascript:void(0);" class="anchor" ref="case"></a>
                <div class="container">
                    <h3 class="wow fadeInLeft">Successful Cases</h3>
                    <div class="right wow fadeInRight">
                        <div class="swiper-container swiper-case">
                            <div class="swiper-wrapper">
                                <div class="swiper-slide" 
                                    v-for="cItem in caseList"
                                    :key="cItem.id">
                                    <div class="box">
                                        <div class="main">
                                            <div class="name">{{ cItem.title }}</div>
                                            <div class="txt">
                                                {{ cItem.detail }}
                                            </div>
                                        </div>
                                        <div class="imgBg motion" :style="{'backgroundImage':'url('+cItem.pic+')'}"></div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="caseList.length > 4" class="swiper-button-next fcc"><i></i></div>
                            <div v-if="caseList.length > 4" class="swiper-button-prev fcc"><i></i></div>
                            <div class="swiper-pagination"></div>
                        </div>
                        <router-link to="/case" class="read-more wow fadeInUp">Read More<i></i></router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { WOW } from 'wowjs'
    import Swiper from 'swiper'
    import 'swiper/css/swiper.min.css'
    import { getSolutionInfo, getSolutionList } from '../../request/api'
    import _isMobile from '../../utils'
    export default {
        name:'Solution',
        metaInfo:{
            title:"DMEGC Solar-solution",
            meta: [{                 // set meta
                name: 'keyWords',
                content: 'DMEGC Solar'
            },{
                name:'description',
                content:'DMEGC Solar'
            }]
        },
        props:[ 'id' ],
        data(){
            return {
                navList:[],
                introduce:{
                    title:'',
                    detail:'',
                    pic:'',
                    banner:'',
                    banner_txt:''
                },
                proList:[],
                pramTitle:'',
                pramList:[],
                caseList:[],
                sId:'',
                isMobile:false
            }
        },
        watch:{
            $route: function() {
                
                this.sId = this.id
                this.showSolutionData()
                this.$refs.solution.scrollIntoView()
            }
        },
        mounted(){
            this.isMobile = _isMobile();
            this.initSolution();
        },
        methods:{
            async initSolution(){

                await getSolutionList().then( res => {

                    this.navList = res.data
                })

                if( this.id === undefined ) {

                    this.sId = this.navList[0].id
                } else {

                    this.sId = this.id
                }

                await this.showSolutionData();
            },
            showSolutionData(){

                getSolutionInfo({ id: this.sId }).then( res => {

                    this.introduce.title = res.data.detail.intro_title
                    this.introduce.detail = res.data.detail.intro
                    this.introduce.pic = res.data.detail.pic
                    this.introduce.banner = res.data.detail.banner
                    this.introduce.banner_txt = res.data.detail.sub_title
                    this.pramTitle = res.data.detail.program_value
                    this.pramList = res.data.detail.program_list
                    this.proList = res.data.proList
                    this.caseList = res.data.caseList

                    this.$nextTick(() =>{

                        const wow = new WOW({
                            animateClass: 'animated',
                            offset: 100
                        })
                        wow.init();
                        this.$refs.solution.scrollIntoView();  
                        if( this.isMobile ) {

                            new Swiper('.swiper-typical',{
                                slidesPerView: 1,
                                spaceBetween: 10,
                                observer: true,
                                navigation: {
                                    nextEl: '.swiper-typical .swiper-button-next',
                                    prevEl: '.swiper-typical .swiper-button-prev',
                                },
                                pagination: {
                                    el: '.swiper-typical .swiper-pagination',
                                },
                            });

                            new Swiper('.swiper-case',{
                                slidesPerView: 1,
                                spaceBetween: 10,
                                observer: true,
                                navigation: {
                                    nextEl: '.swiper-case .swiper-button-next',
                                    prevEl: '.swiper-case .swiper-button-prev',
                                },
                                pagination: {
                                    el: '.swiper-case .swiper-pagination',
                                },
                            });
                        } else {

                            new Swiper('.swiper-typical',{
                                slidesPerView: 3,
                                spaceBetween: 10,
                                observer: true,
                                navigation: {
                                    nextEl: '.swiper-typical .swiper-button-next',
                                    prevEl: '.swiper-typical .swiper-button-prev',
                                },
                                pagination: {
                                    el: '.swiper-typical .swiper-pagination',
                                },
                            });

                            new Swiper('.swiper-case',{
                                slidesPerView: 2,
                                spaceBetween: 2,
                                slidesPerColumnFill: 'row',
                                slidesPerColumn: 2,
                                observer: true,
                                navigation: {
                                    nextEl: '.swiper-case .swiper-button-next',
                                    prevEl: '.swiper-case .swiper-button-prev',
                                },
                                pagination: {
                                    el: '.swiper-case .swiper-pagination',
                                },
                            });
                        }
                    })
                })
            },
            goAnchor( num ){

                if( num === 0 ) {

                    this.$refs.intr.scrollIntoView({behavior: "smooth"});
                } else if( num === 1) {
                    
                    this.$refs.prog.scrollIntoView({behavior: "smooth"});
                }else if ( num === 2 ) {

                    this.$refs.typi.scrollIntoView({behavior: "smooth"});
                }else{

                    this.$refs.case.scrollIntoView({behavior: "smooth"});
                }   
            }
        }
    }
</script>

<style lang="scss" scoped>
.solution{
    position: relative;
    .sub-banner{
        .nav{
            position: absolute;
            left: 0;
            bottom: 0;
            height: 3.125vw;
            border-top: 1px solid rgba(255,255,255,0.3);
            box-sizing: border-box;
            width: 100%;
            .container{
                display: flex;
            }
            a{
                display: block;
                font-size: 0.9896vw;
                line-height: 3.125vw;
                box-sizing: border-box;
                margin-top: -1px;
                border-top: 1px solid transparent;
                height: 3.125vw;
                color: #fff;
                margin-right: 4.5833vw;
            }
            .active{
                border-top-color: #E60011;
                font-weight: bold;
            }
        }
    }
    .introduce-box{
        padding: 4.1667vw 0;
        background: #F2F4F8;
        .container{
            display: flex;
            justify-content: space-between;
        }
        .right{
            display: flex;
        }
        .imgBg{
            width: 50%;
            height: 26.0417vw;
        }
        .main{
            width: 50%;
            height: 26.0417vw;
            box-sizing: border-box;
            background: #232323;
            padding: 3.6458vw 5.2083vw 0;
            .name{
                font-size: 1.5625vw;
                line-height: 1.9792vw;
                font-weight: bold;
                color: #fff;
            }
            .info{
                font-size: 0.9375vw;
                line-height: 1.3542vw;
                color: #fff;
                font-weight: 300;
                margin-top: 1.25vw;
            }
        }
    }
    .program-box{
        padding: 4.1667vw 0 4.6875vw;
        .container{
            display: flex;
            justify-content: space-between;
        }
        .info{
            font-size: 1.5625vw;
            line-height: 1.9792vw;
            color: rgba(0, 0, 0, 0.6);
            width: 46.875vw;
        }
        .list{
            margin-top: 4.6875vw;
            display: flex;
        }
        .item{
            margin-right: 5.2083vw;
            width: 16.6667vw;
            &:last-child{
                margin-right: 0;
            }
            .img{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 3.3333vw;
                height: 3.3333vw;
                img{
                    display: block;
                    max-width: 100%;
                    max-height: 3.3333vw;
                }
            }
            .name{
                font-size: 1.0417vw;
                line-height: 1.25vw;
                font-weight: bold;
                margin-top: 1.5625vw;
                color: rgba(0, 0, 0, 0.8);
                max-width: 11.4583vw;
            }
            .txt{
                margin-top: 1.25vw;
                font-size: 0.8333vw;
                line-height: 1.3542vw;
                font-weight: 300;
                color: rgba(0, 0, 0, 0.8);
            }
        }
    }
    .typical-box{
        padding: 4.1667vw 0;
        background: #F2F4F8;
        .container{
            display: flex;
            justify-content: space-between;
        }
        .swiper-typical{
            height: 27.0833vw;
            padding-bottom: 4.1667vw;
            a{
                display: block;
                height: 27.0833vw;
                box-sizing: border-box;
                background: #fff;
                padding: 2.0833vw 1.0417vw 0;
                position: relative;
                &:hover{
                    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.05);
                    &::before{
                        content: '';
                        width: 100%;
                        height: 100%;
                        box-sizing: border-box;
                        border:3px solid #E60011;
                        position: absolute;
                        left: 0;
                        top: 0;
                    }
                    .more{
                        background: #E60011;
                        width: 100%;
                        left: 0;
                        border-top-color: #E60011;
                        padding: 0 1.0417vw;
                        box-sizing: border-box;
                        color: #fff;
                        i{
                            background-image: url('../../assets/images/arrowR.png');
                        }
                    }
                }
            }
            .name{
                font-size: 1.4583vw;
                line-height: 1.875vw;
                text-align: center;
                font-weight: 600;
                color: rgba(0, 0, 0, 0.8);
            }
            .txt{
                font-size: 14px;
                line-height: 1.1458vw;
                color: rgba(0, 0, 0, 0.6);
                text-align: center;
                margin-top: 0.8333vw;
            }
            .img{
                display: flex;
                justify-content: center;
                align-items: center;
                height: 12.7604vw;
                margin-top: 1.3542vw;
                img{
                    display: block;
                    max-width: 100%;
                    max-height: 12.7604vw;
                }
            }
            .more{
                position: absolute;
                width: calc(100% - 2.0833vw);
                height: 2.9167vw;
                border-top: 1px solid #EAECF1;
                left: 1.0417vw;
                bottom: 0;
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 0.8333vw;
                color: rgba(0, 0, 0, 0.8);
                i{
                    display: block;
                    width: 1.25vw;
                    height: 1.25vw;
                    background: url('../../assets/images/arrowRB.png') no-repeat;
                    background-size: cover;
                }
            }
        }
    }
    .swiper-container{
        .swiper-button-next,
        .swiper-button-prev{
            left: 0;
            top: auto;
            margin: 0;
            bottom: 0;
            width: 2.6042vw;
            height: 2.6042vw;
            background: #232323;
            &::after{
                display: none;
            }
            i{
                display: block;
                width: 1.25vw;
                height: 1.25vw;
                background: url('../../assets/images/arrowR.png') no-repeat;
                background-size: cover;
                transform: rotate(180deg);
            }
            &:hover{
                background: #E60011;
            }
        }
        .swiper-button-next{
            margin-left: calc(2.6042vw + 2px);
            i{
                transform: rotate(0deg);
            }
        }
        .swiper-pagination{
            display: none;
        }
    }
    .case-box{
        padding: 4.1667vw 0;
        .container{
            display: flex;
            justify-content: space-between;
        }
        .right{
            position: relative;
        }
        .swiper-case{
            padding-bottom: 4.1667vw;
            height: 21.1458vw;
            .swiper-slide{
                height: 10.5208vw;
                background: #F2F4F8;
                .box{
                    position: relative;
                    overflow: hidden;
                    height: 10.5208vw;
                    &::before{
                        content: '';
                        box-sizing: border-box;
                        width: 100%;
                        height: 100%;
                        border:3px solid #E60011;
                        position: absolute;
                        left: 0;
                        top: 0;
                        z-index: 16;
                        opacity: 0;
                        transition:all 0.5s ease-in-out;
                        -webkit-transition:all 0.5s ease-in-out;
                        -moz-transition:all 0.5s ease-in-out;
                        -o-transition:all 0.5s ease-in-out;
                    }
                    &:hover{
                        .imgBg{
                            transform: scale(1.1,1.1);
                        }
                        .name,
                        .txt{
                            color: #fff;
                        }
                        &::before{
                            opacity: 1;
                        }
                    }
                }
                .main{
                    position: relative;
                    z-index: 10;
                    padding: 1.0417vw;
                }
                .name{
                    font-size: 1.5625vw;
                    line-height: 2.5vw;
                    color: #fff;
                }
                .txt{
                    font-size: 0.9375vw;
                    line-height: 1.3542vw;
                    margin-top: 1.0417vw;
                    color: #fff;
                    font-weight: 300;
                }
                .imgBg{
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .read-more{
            position: absolute;
            right: 0;
            bottom: 0;
            z-index: 10;
        }
    }
}

@media screen and (max-width: 750px) {
    .solution{
        .sub-banner{
            .nav{
                height: 1rem;
                a{
                    font-size: .3rem;
                    line-height: 1rem;
                    margin-right: .4rem;
                    &:last-child{
                        margin-right: 0;
                    }
                }
            }
        }
        .introduce-box{
            padding: .8rem 0;
            .container,
            .right{
                display: block;
            }
            .imgBg{
                width: 100%;
                height: 5.95rem;
            }
            .main{
                padding: .4rem .3rem;
                height: auto;
                width: 100%;
                .name{
                    font-size: .3rem;
                    line-height: .5rem;
                }
                .info{
                    font-size: .26rem;
                    line-height: .44rem;
                    margin-top: .3rem;
                }
            }
        }
        .program-box{
            padding: .8rem 0;
            .container{
                display: block;
            }
            .info{
                font-size: .26rem;
                line-height: .44rem;
                width: 100%;
            }
            .list{
                display: block;
            }
            .item{
                width: 100%;
                margin: .6rem 0 0 0;
                .img{
                    width: .8rem;
                    height: .8rem;
                    img{
                        max-height: .8rem;
                    }
                }
                .name{
                    font-size: .28rem;
                    line-height: .48rem;
                    max-width: none;
                }
                .txt{
                    margin-top: .3rem;
                    font-size: .24rem;
                    line-height: .42rem;
                }
            }
        }
        .typical-box{
            padding: .8rem 0;
            .container{
                display: block;
            }
            .swiper-typical{
                padding-bottom: .8rem;
                height: 8rem;
                a{
                    height: 8rem;
                    padding: .3rem .3rem 0;
                }
                .name{
                    font-size: .3rem;
                    line-height: .5rem;
                }
                .txt{
                    font-size: .26rem;
                    line-height: .44rem;
                }
                .img{
                    height: 4.2rem;
                    margin-top: .3rem;
                    img{
                        max-height: 4.2rem;
                    }
                }
                .more{
                    left: .3rem;
                    width: calc(100% - .6rem);
                    height: .8rem;
                    font-size: .26rem;
                    i{
                        width: .3rem;
                        height: .3rem;
                    }
                }
            }
        }
        .case-box{
            padding: .8rem 0;
            .container{
                display: block;
            }
            .right{
                padding-bottom: 1.2rem;
            }
            .swiper-case{
                padding-bottom: .8rem;
                height: 2.5rem;
                .swiper-slide{
                    height: 2.5rem;
                    .box{
                        height: 2.5rem;
                        &::before{
                            border-width: 2px;
                        }
                    }
                    .main{
                        padding: .4rem;
                    }
                    .name{
                        font-size: .3rem;
                        line-height: .5rem;
                    }
                    .txt{
                        font-size: .26rem;
                        line-height: .44rem;
                        margin-top: .1rem;
                    }
                }
            }
            .read-more{
                right: auto;
                left: 0;
            }
        }
        .swiper-container{
            .swiper-button-next,
            .swiper-button-prev{
                display: none;
            }
            /deep/.swiper-pagination{
                display: flex;
                justify-content: center;
                bottom: 0;
                .swiper-pagination-bullet{
                    width: .12rem;
                    height: .12rem;
                    margin: 0 .12rem;
                    background: #AFBAC7;
                }
                .swiper-pagination-bullet-active{
                    background: #E60011;
                }
            }
        }
    }
}
</style>